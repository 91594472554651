body {
  font-size: 10pt;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  color: black;
  line-height: 14pt;
  padding-left: 5pt;
  padding-right: 5pt;
  padding-top: 5pt;
}

h1 {
  font: 16pt Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 20pt;
}

p.subheader {
  font-weight: bold;
  color: #593d87;
}

img {
  padding: 3pt;
  float: right;
}

a {
  text-decoration: none;
}

a:link, a:visited {
  color: #8094d6;
}

a:hover, a:active {
  color: #FF9933;
}

div.footer {
  font-size: 9pt;
  font-style: italic;
  line-height: 12pt;
  text-align: center;
  padding-top: 30pt;
}

.rTable {
  display: table;
  width: 100%;
}
.rTableRow {
  display: table-row;
  vertical-align: middle;
}
.rTableHeading {
  display: table-header-group;
  background-color: #ddd;
}
.rTableCell, .rTableHead {
  display: table-cell;
  padding: 3px 10px;
  border: 1px solid #999999;
  vertical-align: middle;
}
.rTableHeading {
  display: table-header-group;
  background-color: #ddd;
  font-weight: bold;
}
.rTableFoot {
  display: table-footer-group;
  font-weight: bold;
  background-color: #ddd;
}
.rTableBody {
  display: table-row-group;
}

button {
  margin-left: 5px;
  background: blue;
  border-radius: 8px;
  color: white;
}

button:disabled {
  background: gray;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #8094d6;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
  color: #FF9933;
}

.message-area {
  font-weight: bold;
}


